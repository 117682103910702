export default {
  title(title) {
    title = title ? title + ' - Home' : 'baidupcs-web';
    window.document.title = title;
  },
  formatDateTime(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return `${y}-${m}-${d} ${h}:${minute}:${second}`;
  },
  bytesToSize(bytes) {
    if (bytes == 0) return '0 B';
    var k = 1024,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

    return (bytes / Math.pow(k, i)).toFixed(2) + ' ' + sizes[i];
  },
  sleep(delay = 1000) {
    return new Promise(resolve => {
      setTimeout(() => resolve(), delay)
    })
  }
}